(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/services/fetch-templates.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/services/fetch-templates.js');
"use strict";


const {
  helpers
} = svs.components.marketplaceRetailAdminData.gameTemplates;
const logger = new svs.core.log.Logger('marketplace:client');

const fetchTemplates = async callback => {
  const apiVersion = 4;
  const offset = 500;
  try {
    const initialResponse = await svs.core.jupiter.get("/retailer/".concat(apiVersion, "/marketplace/groups/containers?offset=0&count=500"));
    const firstFetchOfContainers = initialResponse.containers || [];
    const totalHits = initialResponse.totalHits;
    if (totalHits <= offset) {
      return callback(null, {
        templates: firstFetchOfContainers.map(helpers.mapGameTemplate),
        users: []
      });
    }
    const remainingRequests = [];
    for (let i = 1; i <= Math.ceil((totalHits - offset) / offset); i++) {
      remainingRequests.push(svs.core.jupiter.get("/retailer/".concat(apiVersion, "/marketplace/groups/containers?offset=").concat(offset * i, "&count=500")));
    }
    const remainingResponses = await Promise.all(remainingRequests);
    remainingResponses.forEach(response => {
      firstFetchOfContainers.push(...(response.containers || []));
    });
    callback(null, {
      templates: firstFetchOfContainers.map(helpers.mapGameTemplate),
      users: []
    });
  } catch (error) {
    callback(error.responseJSON ? error.responseJSON.error : error.message);
  }
};
setGlobal('svs.components.marketplaceRetailAdminData.gameTemplates.services.fetchTemplates', fetchTemplates);

 })(window);